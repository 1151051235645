<template>
  <div class="wap-address">
    <headerBar :title="$t('di-zhi-0')" @back="back"></headerBar>

    <div class="address-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
      >
        <div
          class="address-box flex-center-between"
          v-for="(item, i) in dataList"
          :key="i"
          @click="chooseAddress(item)"
        >
          <div>
            <div class="flex-center-start user">
              <i class="el-icon-location-outline"></i>
              <div>{{ item.FullName }}{{ item.Phone }}</div>
              <div class="tag" v-if="item.IsSelected == 1">
                {{ $t('mo-ren') }}
              </div>
            </div>
            <div class="address">
              {{ item.Country }} {{ item.Province }} {{ item.City }}
              {{ item.Address }}
            </div>
          </div>
          <div class="btn">
            <div
              class="link"
              v-if="item.IsSelected != 1"
              @click.stop="addressAction(1, item)"
            >
              {{ $t('she-wei-mo-ren') }}
            </div>
            <div class="red" @click.stop="addressAction(2, item)">
              {{ $t('shan-chu') }}
            </div>
          </div>
        </div>
      </List>
    </div>
    <div class="bottom-btn">
      <Button @click="toAdd">{{ $t('xin-zeng-di-zhi-0') }}</Button>
    </div>
  </div>
</template>
<script>
import { Button, Popup, List, Dialog } from 'vant'
import { getToken } from '@/utils/auth'
import headerBar from '@/components/header'
import {
  shippingAddressList,
  setDefaultAddress,
  deleteAddress
} from '@/api/user'
export default {
  components: {
    Button,
    headerBar,
    Popup,
    List
  },
  data() {
    return {
      form: {},
      showDialog: false,
      dataList: [],
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      pageType: ''
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      shippingAddressList({
        current: this.page.current,
        pageSize: this.page.size
      })
        .then((res) => {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    toAdd() {
      this.$router.push({
        name: 'wapAddAddress'
      })
    },
    chooseAddress(data) {
      if (this.pageType != 'choose') {
        return
      }
      sessionStorage.setItem('addressData', JSON.stringify(data))
      sessionStorage.setItem('addressFlag', 1)
      this.back()
    },
    addressAction(e, data) {
      if (e == 1) {
        //设为默认
        setDefaultAddress({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            this.page.current = 1
            this.$toast(this.$t('she-zhi-cheng-gong'))

            this.init()
          } else {
            this.$toast(res.msg)
          }
        })
      } else {
        //删除
        Dialog.confirm({
          title: this.$t('que-ren'),
          message: this.$t('que-ren-shan-chu-0')
        }).then(() => {
          deleteAddress({
            ID: data.ID
          }).then((res) => {
            if (res.code == 0) {
              this.page.current = 1
              this.$toast(this.$t('shan-chu-cheng-gong'))
              this.init()
            } else {
              this.$toast(res.msg)
            }
          })
        })
      }
    }
  }
}
</script>